import React from 'react';
import { IconBuilding, IconChevronRight, IconRedWarning, IconUserSquare } from '@punchcard/core/icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertPill } from '@punchcard/core';
interface IProps extends React.HTMLAttributes<HTMLElement> {
	studentId: number;
	firstName: string;
	lastName: string;
	ssid: string;
	sites: string[];
	hasReadyToReview: boolean;
}

function StudentCard(props: IProps) {
	const { t } = useTranslation();
	const { studentId, firstName, lastName, className, ssid, sites, hasReadyToReview } = props;
	return (
		<Link
			className={classNames('student-card', className)}
			to={`/students/${studentId.toString()}/courses?tab=1`}
			state={{ firstName: firstName, lastName: lastName }}
		>
			<div>
				<div>
					{firstName} {lastName}
				</div>
				<div className="d-flex align-items-center">
					<IconUserSquare className="text-gray-400" style={{ width: 18, height: 18 }} />
					<small className="text-sm ms-1">{ssid}</small>
					<div className="rounded bg-gray-400 mx-2" style={{ width: 4, height: 4 }}></div>
					<IconBuilding className="text-gray-400 me-1" style={{ width: 18, height: 18 }} />
					<small className="text-sm">{sites?.join(', ')}</small>
				</div>
			</div>
			<div className="d-flex justify-content-between align-items-center">
				{hasReadyToReview &&
					<AlertPill>{t('activity_status.ready_to_review')}</AlertPill>
				}
				<IconChevronRight />
			</div>
		</Link>

	);
}

export default StudentCard;