import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AnswerKeyItem from './AnswerKeyItem';
import classNames from 'classnames';

export enum QuestionType {
	MultipleChoice = 'multiple_choice',
	TrueFalse = 'true_false',
}

interface IProps {
	isSubmitted: boolean;
}
interface FormStructure {
	examAnswers: TeacherCourseExamAnswerDTO[];
}


function AnswerKey(props: IProps) {
	const { isSubmitted } = props;

	const { control } = useFormContext<FormStructure>();
	const { t } = useTranslation();
	const { fields } = useFieldArray({
		control,
		name: 'examAnswers',
	});

	return (
		<div className="border border-gray-200 rounded-3">
			{fields.length > 0 ? (
				fields.map((field, index) => (
					<AnswerKeyItem
						isSubmitted={isSubmitted}
						index={index}
						key={field.id}
						id={field.id}
						name={`examAnswers.[${index}]`}
						className={classNames(
							{
								'bg-danger-100 border-top border-danger-500': field?.correctAnswer !== field?.studentAnswer,
								'border-bottom border-danger-500':
									field?.correctAnswer !== field?.studentAnswer &&
									(index === fields.length - 1 || (index < fields.length - 1 && fields[index + 1]?.correctAnswer === fields[index + 1]?.studentAnswer)),
								'bg-white': index % 2 === 0 && field?.correctAnswer === field?.studentAnswer,
								'bg-quaternary-300': index % 2 !== 0 && field?.correctAnswer === field?.studentAnswer,
								'rounded-top-3': index === 0,
								'rounded-bottom-3': index === fields.length - 1,
							}
						)}
					/>
				))
			) : (
				<div>{t('courses.no_questions')}</div>
			)}
		</div>
	);
}

export default AnswerKey;