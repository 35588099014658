import React, { useEffect, useState } from 'react';
import { EmptyData, LoadingScreen } from '@punchcard/core';
import documentAPI from 'api/documentsAPI';
import Reader from '@punchcard/core/components/Courses/Reader';
import { useTranslation } from 'react-i18next';
import ResourcesTabs from './ResourcesTabs';
import ResourceGrid from './ResourceGrid';

interface IProps {
	course: TeacherCourseDTO | undefined;
}

function ResourcesDocument(props: IProps) {
	const { course } = props;
	const { t } = useTranslation();
	const [activeDocument, setDocumentTab] = useState<number>(-1);
	const [trigger, setTrigger] = useState<boolean>(false);
	const [loadingCourseDocument, setLoadingCourseDocument] = useState<boolean>(false);
	const documentData = React.useRef<File | null>(null);
	const documentList = React.useRef<TeacherCourseDocumentDTO[] | undefined>(course?.courseDocuments);
	const handleTabClickResort = (docId: string | number) => {
		if (documentList.current) {
			const filteredList = documentList.current.filter((doc) => doc.id !== docId);
			const foundItem = documentList.current.find((doc) => doc.id === docId);
			if (foundItem && filteredList) {
				documentList.current = [foundItem, ...filteredList];
			}
		}
		const index = documentList.current?.findIndex((doc) => doc.id === docId) ?? -1;
		documentData.current = null;
		setDocumentTab(index);
		setTrigger(!trigger);
	};
	const handleTabClick = (docId: number) => {
		const index = documentList.current?.findIndex((doc) => doc.id === docId) ?? -1;
		documentData.current = null;
		setDocumentTab(index);
	};
	useEffect(() => {
		async function fetchCourseDocument(documentId: number | undefined) {
			try {
				setLoadingCourseDocument(true);
				if (documentId) {
					const documentUrl = await documentAPI.getCourseDocumentById(documentId);
					const response = await fetch(documentUrl);
					if (!response.ok) {
						throw new Error('error fetching document');
					}
					const blob = await response.blob();
					const document = new File([blob], 'document.pdf', { type: 'application/pdf' });
					documentData.current = document;
				}
			} catch (error) {
				console.error('error fetching courses', error);
			} finally {
				setLoadingCourseDocument(false);
			}
		}
		if (activeDocument >= 0) {
			fetchCourseDocument(documentList.current?.[activeDocument]?.id);
		}
	}, [course, activeDocument, trigger]);

	const renderTab = () => {
		if (loadingCourseDocument) {
			return <LoadingScreen />;
		}
		if (activeDocument == -1) {
			return <ResourceGrid documents={documentList.current} handleTabClick={handleTabClickResort} />;
		}
		if (documentData.current !== null) {
			return <Reader pdfData={documentData.current} />;
		}

		return (
			<EmptyData
				emptymessage={t('wip.no_resources_for_this_unit')}
				descriptionmessage={t('wip.description_no_resources_for_this_unit')}
				image="empty-resources"
				widthdescription="w-75"
			/>
		);
	};

	return (
		<div className="d-flex flex-column flex-fill overflow-auto border-top border-gray-500 bg-quaternary-400">
			<ResourcesTabs documentList={documentList.current} handleTabClick={handleTabClick} handleTabClickResort={handleTabClickResort} activeDocument={activeDocument} />
			<div className="bg-white rounded-top-3 flex-fill p-3 overflow-auto d-flex ">
				{renderTab()}
			</div>

		</div>
	);
}

export default ResourcesDocument;