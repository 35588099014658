import React from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

// Default screens
import { LoadingPage, LoadingScreen } from '@punchcard/core';
import FourOhFourPage from './screens/404Page';
import FourOhOnePage from './screens/401Page';

// Admin Pages
import TenantListPage from './admin/Tenant/TenantListingPage';
import TenantDetailsPage from './admin/Tenant/TenantDetailsPage';
import TenantUserListPage from './admin/Users/TenantUserListPage';
import TenantUserDetailsPage from './admin/Users/TenantUserDetailsPage';
import RoleListingPage from './admin/Authorization/RoleListingPage';
import RoleDetailsPage from './admin/Authorization/RoleDetailsPage';
import PickListTypeListPage from './admin/PickList/PickListTypeListPage';
import PickListItemListPage from './admin/PickList/PickListItemListPage';
import PickListTypeDetails from './admin/PickList/PickListTypeDetails';
import PickListItemDetails from './admin/PickList/PickListItemDetails';

// User Settings Pages
import UpdateUserInfo from './screens/UpdateUserInfo';
import UserProfile from './screens/UserProfile';
import UpdateUserPassword from './screens/UpdateUserPassword';

// Feedback Pages
import FeedbackList from './screens/feedback/FeedbackList';
import FeedbackDetails from './screens/feedback/FeedbackDetails';
import FeedbackForm from './screens/feedback/FeedbackForm';

// App History Tracking
import { EventEmitter } from 'events';
import AppHistoryTrackerProvider from './screens/feedback/useAppHistoryTracker';
import TrackerWrapper from './context/useTracking';

// Authorization Imports
import { Permissions } from './auth/Permissions';
import RequiresPermissions from './context/RequiresPermissions';

import SideBar from './components/navigation/SideBar';
import { SettingsContext } from './context/SettingsContext';
import Courses from './screens/Courses';
import { ToastContainer } from 'react-toastify';
import Students from './screens/Students';
import Home from './screens/Home';
import Units from './screens/Units';
import Wip from 'screens/Wip';
import Educators from 'screens/educators/Educators';
import EducatorsList from 'screens/educators/EducatorsList';

// Site pages
import SchoolSite from 'screens/sites/SchoolSite';
import SchoolSitesList from 'screens/sites/SchoolSitesList';
import SchoolSiteUsersList from 'screens/sites/SchoolSiteUsersList';

export const eventEmitter = new EventEmitter();
import { AuthContext } from 'context/AuthContext';
import TermsAndConditions from '@punchcard/core/components/Shared/TermsAndConditions';

const AppNavigator = () => {
	const { settings } = React.useContext(SettingsContext);
	const { currentUser } = React.useContext(AuthContext);
	if (!currentUser?.agreedToTermsDate) {
		return <TermsAndConditions />;
	}

	const Root = () => (
		<AppHistoryTrackerProvider>
			<TrackerWrapper>
				<div className="scroll-container-wrapper bg-light">
					<SideBar />
					<Outlet />
				</div>
				<ToastContainer />
			</TrackerWrapper>
		</AppHistoryTrackerProvider>
	);

	const router = createBrowserRouter([
		{
			element: <Root />, // This is the root element that will be rendered
			errorElement: <FourOhFourPage />,
			children: [
				{
					path: '*',
					element: <FourOhFourPage />,
				},
				{
					path: '/',
					element: <Students />,
				},
				{
					path: '401',
					element: <FourOhOnePage />,
				},
				{
					path: 'AzureADB2C/Account/SignOut',
					element: <LoadingScreen />,
				},

				// {
				// 	path: '/students',
				// 	element: <Students />,
				// },
				{
					path: 'students/:studentId/courses',
					element: <Courses />,
				},
				{
					path: 'students/:studentId/courses/:courseId/units',
					element: <Units />,
				},
				{
					path: 'students/:studentId/course/:courseId/unit/:unitId',
					element: <Wip />,
				},
				{
					path: 'educators',
					element: <RequiresPermissions requiredPermissions={[Permissions.ManageRoster]}>
						<EducatorsList />
					</RequiresPermissions>,
				},
				{
					path: 'educators/:educatorId',
					element: <RequiresPermissions requiredPermissions={[Permissions.ManageRoster]}>
						<Educators />
					</RequiresPermissions>,
				},
				{
					path: 'sites',
					element:
						<RequiresPermissions requiredPermissions={[Permissions.ReadSite, Permissions.WriteSite]}>
							<SchoolSitesList />
						</RequiresPermissions>
					,
				},
				{
					path: 'sites/:siteId',
					element: <RequiresPermissions requiredPermissions={[Permissions.ReadSite, Permissions.WriteSite]}>
						<SchoolSite />
					</RequiresPermissions>,
				},
				{
					path: 'sites/:siteId/users',
					element: <RequiresPermissions requiredPermissions={[Permissions.ReadSite, Permissions.WriteSite]} >
						<SchoolSiteUsersList />
					</RequiresPermissions >,
				},
				{
					path: 'admin',
					children: [
						{
							path: 'tenants',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.WriteTenant]}>
									<TenantListPage />
								</RequiresPermissions>
							),
						},
						{
							path: 'tenants/:tenantId',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.WriteTenant]}>
									<TenantDetailsPage />
								</RequiresPermissions>
							),
						},
						{
							path: 'tenants/:tenantId/users',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<TenantUserListPage />
								</RequiresPermissions>
							),
						},
						{
							path: 'tenants/:tenantId/users/:userId',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadUser, Permissions.WriteUser]}>
									<TenantUserDetailsPage />
								</RequiresPermissions>
							),
						},
						{
							path: 'roles',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadRole, Permissions.WriteRole]}>
									<RoleListingPage />
								</RequiresPermissions>
							),
						},
						{
							path: 'roles/:roleId',
							element: (
								<RequiresPermissions requiredPermissions={[Permissions.ReadRole, Permissions.WriteRole]}>
									<RoleDetailsPage />
								</RequiresPermissions>
							),
						},
					],
				},
				{
					path: 'pick-lists',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListTypeListPage />
						</RequiresPermissions>
					),
				},
				{
					path: 'pick-lists/:pickListTypeId',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListTypeDetails />
						</RequiresPermissions>
					),
				},
				{
					path: 'pick-lists/:pickListTypeId/pick-list-items',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListItemListPage />
						</RequiresPermissions>
					),
				},
				{
					path: 'pick-lists/:pickListTypeId/pick-list-items/:pickListItemId',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
							<PickListItemDetails />
						</RequiresPermissions>
					),
				},
				{
					path: 'settings',
					children: [
						{
							path: 'profile',
							element: <UserProfile />,
						},
						{
							path: 'update-info',
							element: <UpdateUserInfo />,
						},
						{
							path: 'update-password',
							element: settings?.allowUserPasswordChange ? <UpdateUserPassword /> : <FourOhFourPage />,
						},
					],
				},
				{
					path: 'feedback',
					element: <FeedbackForm />,
				},
				{
					path: 'feedback/feedbackList',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.ReadPickList, Permissions.ReadRole]}>
							<FeedbackList />
						</RequiresPermissions>
					),
				},
				{
					path: 'feedback/:feedbackId',
					element: (
						<RequiresPermissions requiredPermissions={[Permissions.ReadTenant, Permissions.ReadPickList, Permissions.ReadRole]}>
							<FeedbackDetails />
						</RequiresPermissions>
					),
				},
			],
		},
	]);

	return <RouterProvider router={router} fallbackElement={<LoadingPage />} />;
};

export default AppNavigator;