import { IconChevronDown } from '@punchcard/core/icons';
import React, { useState, useRef } from 'react';
import { Button } from './Button';

interface IProps {
	dropDownList: { label: string; value: number | string }[] | undefined;
	handleClick: (index: number | string) => void;
	dropDownPosition?: 'left' | 'right';
	className?: string;
	children?: React.ReactNode;
}

const ButtonDropDown = (props: IProps) => {
	const {
		dropDownList,
		handleClick,
		dropDownPosition = 'right', // Default to 'left' if not provided
		children,
		...otherProps
	} = props;

	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const toggleDropdown = () => {
		setIsOpen((prev) => !prev);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const handleInternalClick = (id: number | string) => {
		handleClick(id);
		setIsOpen(false);
	};

	// Determine dropdown alignment style
	const dropdownStyle = {
		left: dropDownPosition === 'left' ? '0' : 'auto',
		right: dropDownPosition === 'right' ? '0' : 'auto',
		zIndex: 1000,
		width: '300px'
	};

	return (
		<span className="position-relative" ref={dropdownRef}>
			<Button
				className={`flex-fill d-flex align-items-center justify-content-center ${props.className}`}
				onClick={() => {
					toggleDropdown();
				}}
				{...otherProps}
			>
				{children}
				<IconChevronDown />
			</Button>
			{isOpen && (
				<div
					className="bg-quaternary-400 text-white position-absolute border rounded-3"
					style={dropdownStyle}
				>
					{dropDownList?.map((doc: { label: string; value: number | string }) => (
						<button
							key={`docSelect-${doc?.value}`}
							className="unit-tab rounded-0 flex-fill d-flex w-100"
							onClick={() => handleInternalClick(doc.value)}
						>
							{doc.label}
						</button>
					))}
				</div>
			)}
		</span>
	);
};

export default ButtonDropDown;
