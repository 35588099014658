import React from 'react';
import classNames from 'classnames';
import { IconLayoutGrid } from '@punchcard/core/icons';
import ButtonDropDown from '@punchcard/core/components/ButtonDropDown';
import { useTranslation } from 'react-i18next';
interface IProps {
	documentList: TeacherCourseDocumentDTO[] | undefined;
	handleTabClick: (docId: number) => void;
	handleTabClickResort: (docId: number | string) => void;
	activeDocument: number;
}

function ResourceTabs(props: IProps) {
	const { t } = useTranslation();
	const { documentList, handleTabClick, handleTabClickResort, activeDocument } = props;
	return (
		<header className="mt-2 mx-3 d-flex justify-content-between">
			<button
				key={0}
				className={classNames('unit-tab flex-fill d-flex align-items-center justify-content-center', { active: -1 == activeDocument })}
				onClick={() => {
					handleTabClick(0);
				}}
			>
				<span ><IconLayoutGrid /></span>
			</button>
			{documentList?.filter((_, index) => index < 2).map((doc: TeacherCourseDocumentDTO, index: number) => (
				<button
					key={doc?.id}
					className={classNames('unit-tab flex-fill d-flex align-items-center justify-content-center', { active: index == activeDocument })}
					onClick={() => {
						handleTabClick(doc.id);
					}}
				>
					<span >{t(`documentType.${doc.documentTypeCode}`)}</span>
				</button>
			))}
			{documentList && documentList.length > 2 &&
				<ButtonDropDown
					className="unit-tab"
					dropDownPosition="right"
					dropDownList={documentList?.filter((_, index) => index >= 2).map((doc, index) => ({ label: t(`documentType.${doc.documentTypeCode}`), value: doc.id }))}
					handleClick={handleTabClickResort}
				/>
			}
		</header>
	);
}

export default ResourceTabs;