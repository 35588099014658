import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { ExamRadioGroup, FormGroup } from '@punchcard/core';
import { QuestionType } from './AnswerKey';

interface IProps {
	className?: string;
	name: string;
	index: number;
	id: string;
	isSubmitted: boolean;
}
const trueFalseOptions = [
	{ label: 'A', value: 'A' },
	{ label: 'B', value: 'B' },
];

const multipleChoiceOptions = [
	{ label: 'A', value: 'A' },
	{ label: 'B', value: 'B' },
	{ label: 'C', value: 'C' },
	{ label: 'D', value: 'D' },
];

function AnswerKeyItem(props: IProps) {
	const { className, name, index } = props;
	const { control, watch } = useFormContext();
	const type: QuestionType = watch(`${name}.questionType`) as QuestionType;
	const correctAnswer = watch(`${name}.correctAnswer`);

	return (
		<div
			className={classNames(
				'ps-3 d-flex align-items-center',
				className
			)}
			style={{ height: 44 }}
		>
			<div className="d-flex align-items-center">
				<div className="fw-bold" style={{ width: 15 }}>
					{index + 1}
				</div>
			</div>
			<div className="d-flex align-items-center justify-content-between">
				<Controller
					control={control}
					name={`${name}.studentAnswer`}

					render={({ field, fieldState }) => (
						<FormGroup field={field} fieldState={fieldState} className="mb-0 ms-3">
							<ExamRadioGroup
								name={name}
								value={field.value}
								onChange={field.onChange}
								options={getOptionsForQuestionType(type)}
								className="me-4"
								correctValue={correctAnswer}
								isStudent={false}
							/>
						</FormGroup>
					)}
				/>
			</div>
		</div>
	);
	function getOptionsForQuestionType(questionType: QuestionType) {
		//return values.map((value, index) => ({ label: value.answerLetter, value: value.answerId, selectedAnswer: value.selectedAnswer, isCorrect: value.correctAnswer }));
		switch (questionType) {
			case QuestionType.MultipleChoice:
				return multipleChoiceOptions;
			case QuestionType.TrueFalse:
				return trueFalseOptions;
			default:
				return [];
		}
	}
}

export default AnswerKeyItem;