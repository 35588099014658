import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertPill, Button } from '@punchcard/core';
import { IconBook, IconChevronDown, IconChevronUp, IconRedWarning, IconUnfoldMore } from '@punchcard/core/icons';
import LessonsAccordion from './LessonsAccordion';
import { useParams } from 'react-router-dom';

interface IProps {
	unitId: number;
	unitName: string;
	unitNumber: string;
	lessons: TeacherCourseLessonDTO[];
	hasReadyToReview: boolean;
}

function UnitsAccordion(props: IProps) {
	const { t } = useTranslation();
	const { unitId, unitName, unitNumber, hasReadyToReview, lessons } = props;
	const { courseId, studentId } = useParams();
	const [expand, setExpand] = React.useState<boolean>(false);
	const [expandAll, setExpandAll] = React.useState<boolean>(false);

	return (
		<div className="d-flex flex-column bg-white rounded p-2 pe-3">
			<div className="d-flex justify-content-between align-items-center w-100 ">
				<div className="d-flex">
					<Button className="btn-ghost-primary p-0">
						{expand ? <IconChevronUp onClick={() => setExpand(!expand)} /> : <IconChevronDown onClick={() => setExpand(!expand)} />}
					</Button>
					<IconBook className="ms-3" />
					<span>{t('units.unit')} {unitNumber}: </span>
					<span>{unitName}</span>

				</div>
				<div>
					{hasReadyToReview && (
						<AlertPill className="me-2" />
					)}
					<Button className="btn-outline-primary py-1 px-2" to={`/students/${studentId}/course/${courseId}/unit/${unitId}`}>{t('units.view_work')}</Button>
				</div>
			</div>
			<div>
				{expand && lessons.length > 0 && (
					<div className="d-flex flex-column">
						<div className="mb-2">
							<Button className="btn-ghost-primary py-1" onClick={() => setExpandAll(!expandAll)}>
								{t('expand_all')}
								<IconUnfoldMore style={{ width: 18 }} />
							</Button>
						</div>
						{lessons.map((lesson, lessonIndex) => (
							<div key={lesson.id} className="ms-2 mb-2">
								<LessonsAccordion lessonName={lesson.lessonName} hasReadyToReview={lesson.hasReadyToReview} lessonNumber={lessonIndex + 1} activities={lesson.courseActivities} expanded={expandAll} />
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default UnitsAccordion;