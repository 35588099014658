import React from 'react';
import { AlertPill, Button } from '@punchcard/core';
import { IconBookmark, IconChevronDown, IconChevronUp, IconImportContacts, IconRedWarning } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
interface IProps {
	lessonName: string;
	lessonNumber: number;
	hasReadyToReview: boolean;
	activities: TeacherCourseActivityDTO[];
	expanded: boolean;
}

function LessonsAccordion(props: IProps) {
	const { lessonName, lessonNumber, activities, expanded, hasReadyToReview } = props;
	const [expand, setExpand] = React.useState<boolean>(false);
	const { t } = useTranslation();
	React.useEffect(() => {
		setExpand(expanded);
	}, [expanded]);

	return (
		<div className="d-flex flex-column bg-quaternary-200 rounded p-3">
			<div className="d-flex justify-content-between align-items-center w-100 ">
				<div>
					<Button className="btn-ghost-primary p-0">
						{expand ? <IconChevronUp onClick={() => setExpand(!expand)} /> : <IconChevronDown onClick={() => setExpand(!expand)} />}
					</Button>
					<IconImportContacts className="ms-3 me-2 icon-27" />
					<span className="fw-bold">{lessonNumber}: {lessonName}</span>
				</div>
				{hasReadyToReview && (
					<IconRedWarning className="icon bg-tertiary-100 text-tertiary-700 rounded-5 p-1 me-2 ms-auto" />
				)}
			</div>
			{expand && (
				<div className="d-flex flex-column mt-3">
					{activities.map((activity) => (
						<div key={activity.id} className="d-flex align-items-center ms-5 my-2">
							<IconBookmark className="text-secondary-500" style={{ width: 18 }} />
							<span><small>{activity.activityName}</small></span>
							{activity.hasReadyToReview && (
								<AlertPill className="ms-auto">{t('activity_status.ready_to_review')}</AlertPill>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default LessonsAccordion;