import axios from 'axios';

const documentAPI = {
	getCourseDocument: async (courseId: number, courseUnitId: number): Promise<string> => {
		return axios.get(`/teacher/activityDocument/courseId/${courseId}/courseUnitId/${courseUnitId}`).then(res => res.data);
	},

	getListCourseDocuments: async (): Promise<TeacherCourseWithUnitsDTO[]> => {
		return await axios.get('/teacher/courses', {
		}).then(res => res.data as TeacherCourseWithUnitsDTO[]);
	},
	getStudentLayer: async (studentId: string, courseId: string, unitId: string): Promise<string> => {
		return axios.get(`/teacher/studentLayer/${studentId}/${courseId}/${unitId}`).then(res => res.data);
	},
	getCourseDocumentById: async (documentId: number): Promise<string> => {
		return axios.get(`/teacher/courseDocument/${documentId}`).then(res => res.data);
	},
	getStudentCourseUnit: async (courseId: string, studentId: string): Promise<TeacherCourseDTO> => {
		return axios.get(`/teacher/courseunits/${courseId}/${studentId}`, {
		}).then(res => res.data as TeacherCourseDTO);
	},
	postCourseActivityStatus: async (courseActivityId: number, studentId: string, status: string, type: string | undefined, gradeReceived: number | undefined) => {
		const formData = {
			courseActivityId,
			studentId,
			status,
			type,
			gradeReceived,
		};
		return axios.post('/teacher/courseactivitystatus', formData, {
		}).then(res => res.data);
	},
	getStudentExamStudentAnswers: async (studentId: string, courseAssessmentId: number): Promise<TeacherCourseExamStudentAnswerDTO> => {
		return axios.get(`/teacher/courseexam/courseAssessmentId/${courseAssessmentId}/studentId/${studentId}`, {
		}).then(res => res.data as TeacherCourseExamStudentAnswerDTO);
	},
	postStudentExamGrade: async (data: TeacherCourseExamStudentAnswerDTO): Promise<TeacherCourseExamStudentAnswerDTO> => {
		return axios.post(`/teacher/courseexam/courseAssessmentId/${data.courseAssessmentId}/studentId/${data.studentId}`, data, {

		}).then(res => res.data as TeacherCourseExamStudentAnswerDTO);
	}
};

export default documentAPI;