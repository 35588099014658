import React, { useEffect, useRef } from 'react';
import { Button, ErrorMessage, FormGroup, Input, LoadingScreen, ModalOverlay, TextArea } from '@punchcard/core';
import ExamPlane from '@punchcard/core/assets/exam-plane.png';
import { useTranslation } from 'react-i18next';
import documentAPI from 'api/documentsAPI';
import Reader from '@punchcard/core/components/Courses/Reader';
import AnswerKey from './AnswerKey';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { IconCheck, IconClose } from '@punchcard/core/icons';
import classNames from 'classnames';

interface IProps {
	currentActivityItem: TeacherCourseActivityDTO;
	studentId: string | undefined;
	review: boolean;
	setReview: (value: boolean) => void;
}
const TeacherExamLaunch: React.FC<IProps> = (props) => {
	const { currentActivityItem, studentId, review, setReview } = props;
	const { t } = useTranslation();
	const [preview, setPreview] = React.useState(false);
	const [loadingPDF, setLoadingPDF] = React.useState(false);
	const [loadingAnswers, setLoadingAnswers] = React.useState(true);
	const pdfData = useRef<File | null>(null);
	const methods = useFormContext<TeacherCourseExamStudentAnswerDTO>();

	const { reset, watch, control } = methods;

	const examName = watch('examName');
	const nbCorrectAnswers = watch('gradeReceived');

	useEffect(() => {
		const downloadPDF = async () => {
			try {
				setLoadingPDF(true);
				documentAPI.getCourseDocumentById(currentActivityItem?.examDocumentId)
					.then(async (documentUrl) => {
						const response = await fetch(documentUrl);

						if (!response.ok) {
							throw new Error('error fetching document');
						}
						const blob = await response.blob();
						const document = new File([blob], 'document.pdf', { type: 'application/pdf' });
						pdfData.current = document;
						setLoadingPDF(false);
					});
			} catch (error) {
				console.error('Error fetching documents:', error);
				setLoadingPDF(false);
			}
		};
		downloadPDF();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preview]);
	useEffect(() => {
		const fetchAnswers = async () => {
			try {
				if (studentId) {
					documentAPI.getStudentExamStudentAnswers(studentId, currentActivityItem?.id)
						.then((res) => {
							const formData: WIPForm = {
								examAnswers: res.examAnswers,
								examName: res.examName,
								gradeReceived: res.gradeReceived,
								reason: res.reason,
								comment: res.comment,
								approval: res.approval,
								studentLayer: [] as ILayerPaths[],
								undoHistory: [],
								undoIndex: null,
								lastAction: null,
							};
							reset(formData);
							setLoadingAnswers(false);
						});
				}
			} catch (error) {
				console.error('Error fetching documents:', error);
				setLoadingAnswers(false);
			}
		};
		fetchAnswers();
		setPreview(false);
		setReview(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function renderPage() {
		if (preview || review) {
			return (
				<div className="d-flex flex-column flex-grow-1 overflow-hidden">
					{preview &&
						<div className="flex-grow-1 d-flex flex-column overflow-hidden">
							<div className="flex-grow-1 overflow-y-auto" >
								{loadingPDF ? <LoadingScreen /> : <Reader pdfData={pdfData.current} />}
							</div>
							<div>
								<Button
									onClick={() => setPreview(false)}
									className="btn btn-outline-primary w-auto my-3"
								>
									{t('exam.exit_preview')}
								</Button>
							</div>
						</div>
					}
					{review &&
						<div className="overflow-hidden d-flex flex-grow-1">
							<div className="bg-white overflow-y-auto p-3 flex-grow-1" style={{ maxWidth: '420px' }}>
								<h5 className="mb-3">{examName}</h5>
								<FormProvider {...methods}>
									<AnswerKey isSubmitted={true} />
								</FormProvider>
							</div>
							<div className="bg-white overflow-y-auto p-3 flex-grow-1" style={{ maxWidth: '420px' }}>
								<Controller
									control={control}
									name="gradeReceived"
									rules={{
										required: t('this_field_is_required'), // Required validation
										validate: {
											maxGrade: (value) =>
												(value ?? 0) <= currentActivityItem?.nbQuestions
												|| t('exam.grade_must_be_less_than_or_equal_to',
													{ count: currentActivityItem?.nbQuestions }), // Custom validation for max value
										},
									}}
									render={({ field, fieldState }) =>
										<FormGroup label={t('exam.grade')} required={true} fieldState={fieldState}>
											<Input
												append={`/ ${currentActivityItem?.nbQuestions.toString()}`}
												{...field}
												error={!!fieldState.error?.message}
											/>

										</FormGroup>
									}
								/>
								<Controller
									control={control}
									name="reason"
									render={({ field }) =>
										<FormGroup label={t('exam.reason')}>
											{t('exam.for_internal_view_only')}
											<TextArea
												rows={4}
												{...field}
											/>
										</FormGroup>
									}
								/>
								<Controller
									control={control}
									name="comment"
									render={({ field }) =>
										<FormGroup label={t('exam.comment')}>
											<TextArea
												rows={4}
												{...field}
											/>
										</FormGroup>
									}
								/>
								<Controller
									control={control}
									name="approval"
									render={({ field: { value, onChange } }) => (
										<FormGroup label={t('exam.approval')}>
											<Button
												className={classNames(
													'failed-btn',
													{ 'failed-btn-selected': value === false }
												)}
												onClick={() => onChange(false)}
											>
												<IconClose className={'me-2'} style={{ width: 18 }} />
												{t('exam.return')}
											</Button>
											<Button
												className={classNames(
													'success-btn',
													{ 'success-btn-selected': value === true }
												)}
												onClick={() => onChange(true)}
											>
												<IconCheck className={'me-2'} style={{ width: 18 }} />
												{t('activity_status.completed')}
											</Button>
										</FormGroup>
									)}
								/>
							</div>

						</div>
					}
				</div>
			);
		} else {
			return (
				<React.Fragment>
					<div className="bg-white p-3 flex-grow-1 d-flex flex-column justify-content-between overflow-y-auto">
						<div>
							<h3>{currentActivityItem?.activityName}</h3>
							<p style={{ whiteSpace: 'pre-wrap' }}>{currentActivityItem?.instructions}</p>
						</div>
						<div>
							<img src={ExamPlane} alt="Exam Logo" className="d-inline-block mb-3 px-2" />
						</div>
					</div>

					<div className="mb-3">
						<Button
							onClick={() => { setReview(true); }}
							className="btn btn-primary mt-3"
						>
							{t('exam.review_exam')}
						</Button>
						<Button
							onClick={() => { setPreview(true); }}
							className="btn btn-outline-primary mt-3 ms-3"
						>
							{t('exam.preview_exam')}
						</Button>
					</div>
				</React.Fragment>
			);
		}
	}

	if (loadingAnswers) {
		return <LoadingScreen />;
	}
	return (
		<React.Fragment>
			<div className="d-flex align-items-center bg-quaternary-400 rounded-top-3 pe-0" style={{ minHeight: 55 }}>
				<div className="d-flex align-items-center rounded-top-3 bg-quaternary-400 flex-grow-1 h-100" >
					<div className="d-flex align-items-center bg-quaternary-800 answer-banner text-white px-3" style={{ height: 55 }} >
						{currentActivityItem?.courseActivityStatus?.statusCode === 'Completed' ? (
							`${nbCorrectAnswers} / ${currentActivityItem?.nbQuestions} = ${Math.round(((nbCorrectAnswers ?? 0) / currentActivityItem?.nbQuestions) * 100)}%`
						) : (
							`${currentActivityItem?.nbQuestions} ${t('exam.questions')}`
						)}

					</div>
				</div>
			</div>
			{renderPage()}
		</React.Fragment>
	);
};

export default TeacherExamLaunch;