import React from 'react';
import { LessonAccordionSummary, UnitTabsSummary } from '@punchcard/core';
import AssessmentAccordionSummary from './AssessmentAccordionSummary';


interface IProps {
	statusCodeMappingActivity: statusCodeMappingActivity;
	statusCodeMappingLesson: statusCodeMappingLesson;
	isStudent: boolean;
	course: StudentCourseDTO | TeacherCourseDTO;
	activeUnit: number;
	handleUnitClick: (index: number) => void;
	handleLessonClick: (id: number) => void;
	activeActivity: number | null;
	handleActivityClick: (index: number | null, currentActivity: currentActivityAssessment) => void;
	safeStatuses: string[];
	openAccordions: number[];
}


function Summary({
	statusCodeMappingActivity,
	statusCodeMappingLesson,
	isStudent,
	course,
	activeUnit,
	handleUnitClick,
	handleLessonClick,
	activeActivity,
	handleActivityClick,
	safeStatuses,
	openAccordions,
}: IProps) {
	const assessments = collectAssessments(course?.courseUnits[activeUnit]);
	const unitGradeTotals: string = getUnitGradeTotals(assessments);
	return (
		<div className="d-flex flex-column flex-fill overflow-auto border-top border-gray-500 bg-quaternary-400">
			<UnitTabsSummary course={course} handleUnitClick={handleUnitClick} activeUnit={activeUnit} isStudent={isStudent} />
			<div className="bg-white rounded-top-3 flex-fill p-3 overflow-auto">
				<h6 className="mb-3">Unit {activeUnit + 1 + ': ' + course?.courseUnits[activeUnit]?.unitName}</h6>
				{course?.courseUnits[activeUnit]?.courseLessons?.map((lesson, index) => (
					<LessonAccordionSummary
						openAccordions={openAccordions}
						lesson={lesson}
						key={lesson.id}
						handleLessonClick={handleLessonClick}
						activeActivity={activeActivity}
						handleActivityClick={handleActivityClick}
						statusCodeMappingActivity={statusCodeMappingActivity}
						statusCodeMappingLesson={statusCodeMappingLesson}
						isStudent={isStudent}
						lessonIndex={index + 1}
						safeStatuses={safeStatuses}
					/>
				))}
				{assessments.length > 0 && <AssessmentAccordionSummary
					assessmentStatus={course?.courseUnits[activeUnit]?.courseAssessmentStatus?.statusCode || 'Open'}
					assessments={assessments}
					handleLessonClick={handleLessonClick}
					activeActivity={activeActivity}
					handleActivityClick={handleActivityClick}
					statusCodeMappingActivity={statusCodeMappingActivity}
					statusCodeMappingLesson={statusCodeMappingLesson}
					isStudent={isStudent}
					safeStatuses={safeStatuses}
				/>
				}
				<div className="d-flex justify-content-end mb-3 fw-bold me-1">{unitGradeTotals}</div>
			</div>

		</div>
	);

	function getUnitGradeTotals(assessments: (StudentCourseActivityDTO | TeacherCourseActivityDTO)[]): string {
		let totalGrades = 0;
		let totalReceived = 0;
		assessments.forEach((assessment) => {
			totalGrades += assessment.gradeValue ?? 0;
			totalReceived += assessment.gradeReceived ?? 0;
		});
		return `${totalReceived}/${totalGrades}`;
	}

	function collectAssessments(courseUnit: StudentCourseUnitDTO | TeacherCourseUnitDTO): (StudentCourseActivityDTO | TeacherCourseActivityDTO)[] {
		const assessments: (StudentCourseActivityDTO | TeacherCourseActivityDTO)[] = [];

		courseUnit.courseLessons?.forEach((lesson) => {
			if (lesson?.courseAssessments) {
				assessments.push(...lesson.courseAssessments);
			}
		});
		if (courseUnit?.courseAssessments) {
			assessments.push(...courseUnit.courseAssessments);
		}

		return assessments;
	}
}

export default Summary;