import React from 'react';
import { IconChevronDown, IconChevronUp } from '@punchcard/core/icons';
import classNames from 'classnames';
import AssessmentSummary from './AssessmentSummary';
import { useTranslation } from 'react-i18next';
interface IProps {
	assessmentStatus: string;
	assessments: (StudentCourseActivityDTO | TeacherCourseActivityDTO)[];
	handleLessonClick: (Id: number) => void;
	handleActivityClick: (index: number | null, currentActivity: currentActivityAssessment) => void;
	activeActivity: number | null;
	statusCodeMappingActivity: statusCodeMappingActivity;
	statusCodeMappingLesson: statusCodeMappingLesson;
	isStudent: boolean;
	safeStatuses: string[];
}

function AssessmentAccordionSummary({
	assessmentStatus,
	assessments,
	activeActivity,
	handleActivityClick,
	statusCodeMappingActivity,
	statusCodeMappingLesson,
	isStudent,
	safeStatuses,
}: IProps) {
	const [isActive, setIsActive] = React.useState<boolean>(false);
	const statusConfig = statusCodeMappingLesson[assessmentStatus] || {};
	const IconComponent = statusConfig.icon;
	const { t } = useTranslation();
	React.useEffect(() => {
		if (assessments) {

			const open = assessments
				.filter(assessment => assessment.courseActivityStatus?.statusCode !== 'Completed' && assessment.courseActivityStatus?.statusCode !== 'ReAttempt')
				.length > 0;
			setIsActive(open);

		}
	}, [assessments]);

	return (
		<div className="mb-2">
			<button
				className={classNames('lesson-accordion', { 'active': isActive })}
				onClick={() => setIsActive(!isActive)}
			>
				<div className="d-flex align-items-c enter flex-grow-1 overflow-hidden">
					<div className="d-flex align-items-center flex-shrink-0">
						{isActive ? <IconChevronUp style={{ width: 18, height: 18 }} className="me-2" /> : <IconChevronDown style={{ width: 18, height: 18 }} className="me-2" />}
						{IconComponent && <IconComponent
							style={{ width: 18, height: 18 }}
							className={classNames('me-2', { [statusConfig.className]: !isActive, [statusConfig.activeIconClassName ?? '']: isActive })}
						/>}
					</div>
					<div className="text-truncate flex-grow-1 text-start">
						{t('wip.assessments')}
					</div>
				</div>
				<span className={classNames('status ms-3 flex-shrink-0', !isActive && statusConfig.className)}>
					{statusConfig.status}
				</span>
			</button>
			{isActive && (
				<div className="lesson-accordion-content border border-quaternary-800 rounded-bottom-3">
					{assessments?.map((assessment, index) => (
						<AssessmentSummary
							key={assessment.id}
							assessment={assessment}
							activeActivity={activeActivity}
							handleActivityClick={handleActivityClick}
							statusCodeMappingActivity={statusCodeMappingActivity}
							isStudent={isStudent}
							assessmentIndex={index + 1}
							safeStatuses={safeStatuses}
						/>
					))
					}
				</div>)
			}
		</div>
	);
}

export default AssessmentAccordionSummary;