import classNames from 'classnames';
import { IconRedWarning } from '@punchcard/core/icons';
import React from 'react';


interface AlertPillProps {
	className?: string;
	type?: 'error';
}

function AlertPill(props: React.PropsWithChildren<AlertPillProps>) {
	const { type = 'error', className, children, ...otherProps } = props;

	const [bgColour, textColour] = React.useMemo(() => {
		switch (type) {
			case 'error':
				return ['bg-danger-100', 'text-danger-700'];
		}
	}, [type]);

	if (!children) {
		return (
			<IconRedWarning className={classNames('icon bg-tertiary-100 text-tertiary-700 rounded-5 p-1', bgColour, textColour, className)} />
		);
	}
	return (
		<div className={classNames('p-1 ps-2 pe-3 rounded-3 text-tertiary-700 align-items-center', bgColour, textColour, className)} {...otherProps}>
			<IconRedWarning className={classNames('me-2', textColour)} />
			{children}
		</div>
	);
}
export default AlertPill;