import React from 'react';
import classNames from 'classnames';
import { IconGreenCheck, IconRedWarning } from '@punchcard/core/icons';
import { useParams, useNavigate } from 'react-router-dom';

interface IProps {
	course: StudentCourseDTO | TeacherCourseDTO;
	handleUnitClick: (index: number) => void;
	activeUnit: number;
	isStudent: boolean;
}

function UnitTabsSummary(props: IProps) {
	const { course, handleUnitClick, activeUnit, isStudent } = props;
	const { courseId, studentId } = useParams();
	const navigate = useNavigate();

	const updateUnit = (newUnitId: number) => {
		navigate(isStudent ? `/course/${courseId}/unit/${newUnitId}` : `/students/${studentId}/course/${courseId}/unit/${newUnitId}`);
	};

	return (
		<header className="mt-2 mx-3 d-flex justify-content-between">
			{course?.courseUnits?.map((unit: StudentCourseUnitDTO | TeacherCourseUnitDTO, index: number) => (
				<button
					key={unit?.id}
					className={classNames('unit-tab flex-fill d-flex align-items-center justify-content-center', { active: index == activeUnit })}
					onClick={() => {
						handleUnitClick(index);
						updateUnit(unit?.id);
					}}
				>
					{unit?.courseUnitStatus?.statusCode === (isStudent ? 'ReAttempt' : 'ReadyForReview') && <IconRedWarning className="me-1 text-danger-600" style={{ width: 18, height: 18 }} />}
					{unit?.courseUnitStatus?.statusCode === 'Completed' && <IconGreenCheck className="me-1" style={{ width: 18, height: 18 }} />}
					<span className={classNames({ 'text-gray-800': unit?.courseUnitStatus?.statusCode === 'Completed' })}>Unit {index + 1}</span>
				</button>
			))}
		</header>
	);
}

export default UnitTabsSummary;