import React, { useEffect, useRef, useState } from 'react';
import { LoadingScreen, PDFReader } from '@punchcard/core';
import documentAPI from 'api/documentsAPI';
import { useFormContext } from 'react-hook-form';
import TeacherExamLaunch from 'components/exam/TeacherExamLaunch';


interface IProps {
	courseId: string | undefined;
	courseUnitId: string | undefined;
	studentId: string | undefined;
	submitDrawing: () => void;
	currentActivity?: currentActivityAssessment;
	statusCodeMappingActivity: statusCodeMappingActivity;
	onStatusChange: (courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) => void;
	isActivityOpen: boolean;
	review: boolean;
	setReview: (value: boolean) => void;
}

const DisplayPDF = (props: IProps) => {
	const { studentId, courseId, courseUnitId, currentActivity, statusCodeMappingActivity, onStatusChange, isActivityOpen, review, setReview } = props;
	const [loading, setLoading] = useState(false);
	const pdfData = useRef<File | null>(null);
	const methods = useFormContext<WIPForm>();
	const isExam = (currentActivity?.item as TeacherCourseActivityDTO)?.assessmentType === 'exam';
	useEffect(() => {
		const downloadPDF = async () => {
			try {
				setLoading(true);
				if (currentActivity) {
					documentAPI.getCourseDocument(currentActivity?.item?.courseId, currentActivity?.item?.courseUnitId)
						.then(async (res) => {
							const response = await fetch(res);
							if (!response.ok) {
								throw new Error('error fetching document');
							}
							const blob = await response.blob();
							const document = new File([blob], 'document.pdf', { type: 'application/pdf' });
							pdfData.current = document;
							setLoading(false);
						});
				}
			} catch (error) {
				console.error('Error fetching documents:', error);
			}
		};
		downloadPDF();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentActivity]);

	const loadStudentLayer = () => {
		if (courseId && courseUnitId && studentId) {
			documentAPI.getStudentLayer(studentId, courseId, courseUnitId)
				.then(async (res) => {
					const response = await fetch(res);
					if (!response.ok) {
						throw new Error('error fetching document');
					}
					const blob = await response.blob();
					const jsonFile = new File([blob], 'studentlayer.json', { type: blob.type });
					const paths = await jsonFile.text();
					if (paths === '') {
						methods.reset({ studentLayer: [] });
					} else {
						const jsonData = JSON.parse(paths) as ILayerPaths[];
						methods.reset({ studentLayer: jsonData });
					}
				});
		}
	};

	const documentLoadSuccess = () => {
		loadStudentLayer();
	};
	const renderPage = () => {
		if (isExam && currentActivity) {
			return <TeacherExamLaunch
				currentActivityItem={currentActivity.item as TeacherCourseActivityDTO}
				studentId={studentId}
				review={review}
				setReview={setReview}
			/>;
		}
		if (!isExam && pdfData.current) {
			return <PDFReader
				pdfData={pdfData.current}
				documentLoadSuccess={documentLoadSuccess}
				isTeacherView={true}
				submitDrawing={props.submitDrawing}
				currentActivity={currentActivity}
				statusCodeMappingActivity={statusCodeMappingActivity}
				onStatusChange={onStatusChange}
				isActivityOpen={isActivityOpen} />;
		}
		if (loading && pdfData.current === null) {
			return <LoadingScreen />;
		}

		return (
			<React.Fragment />
		);
	};

	if (currentActivity === undefined) {
		return (
			<React.Fragment />
		);
	}

	return (
		<div className="scroll-container-wrapper wrapper">
			{renderPage()}
		</div>
	);
};

export default DisplayPDF;